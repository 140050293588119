





























import { Component, Vue } from 'vue-property-decorator'
import MemberRegisterServeice from '@/services/MemberService'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import Logo from '@/components/Logo.vue'

@Component({
  components: {
    LayoutCompact,
    Logo
  }
})
export default class MemberRegisterSuccess extends Vue {
  public is_active: boolean = false;
  public is_not_exist: boolean = false;
  
  created() {
    if (this.$route.params.id) {
      MemberRegisterServeice.registerMemberSuccess(this.$route.params.id).then((response: any) => {
        console.log(response);
      },
      (error: {
        response: { data: any; status: any }
        message: any
        toString: () => any
      }) => {
        if (error.response.status === 440) {
          if (
            error.response.data.message[0] === 'USER_ALREADY_ACTIVATED'
          ) {
            this.is_active = true;
          } else if (
            error.response.data.message[0] ===
            'USER_DOES_NOT_EXIST'
          ) {
            this.is_not_exist = true;
          }
        }
      }
      
      )
    }
  }

  public handleBackLogin() {
    localStorage.removeItem('items')
    localStorage.removeItem('store_id')
    this.$router.push({ name: 'login' })
  }
}
